<template>
  <v-card height="100%" tile class="pa-10">
    <v-col cols="12" sm="12" md="2">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datei"
            label="Selecionar fecha"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            clearable
            v-on="on"
            @click:clear="
              datei = null;
              $router.push(`/recepcion`);
            "
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datei"
          @change="
            menu = false;
            $router.push(`/recepcion?date=${datei}`);
          "
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-card-text class="pa-1">
      <v-container class="pa-2" fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-card-text class="pa-0">
                <table-reception></table-reception>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pa-5">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            class="button-save"
            color="#DAF4D0"
            dark
            dense
            v-bind="attrs"
            v-on="on"
            style="margin-right:10px;color:#479B25"
          >
          <v-img class="mr-4" src="@/assets/images/DNI.svg" />
            Ingresar DNI
          </v-btn>
        </template>
        <v-card>
          <form>
            <v-card-title>
              <span class="text-h5">Ingresar DNI</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="document_number"
                      label="DNI"
                      :error-messages="documentError"
                      :counter="8"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indica campos requeridos</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog()">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="submit"> Guardar </v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            color="primary"
            class="button-save"
            dark
            dense
            v-bind="attrs"
            v-on="on"
             @click="close=false"
          >
           <v-img   class="mr-6" src="@/assets/images/qr-code.svg" />
            <span>
            Escanear QR

            </span>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Escanear QR</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <QrScan :close="close"></QrScan>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeQr()">
              Cerrar
            </v-btn>
            <!-- <v-btn color="blue darken-1" text @click="dialog2 = false">
              Save
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-card>
</template>

<script>
import TableReception from "@/components/tables/TableReception.vue";
import { validationMixin } from "vuelidate";
import QrScan from "@/components/util/QrScan.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "receptions",
  mixins: [validationMixin],
  components: {
    TableReception,
    QrScan,
  },
  validations: {
    document_number: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(8),
    },
  },
  watch: {},
  data: () => ({
    menu: false,
    datei: null,
    dialog: false,
    dialog2: false,
    document_number: null,
    snackbar: false,
    color: "",
    text: "",
    close: false
  }),
  computed: {
    date() {
      return this.$route.query.date;
    },
    documentError() {
      const errors = [];
      if (!this.$v.document_number.$dirty) return errors;
      !this.$v.document_number.required &&
        errors.push("Documento es requerido");
      return errors;
    },
  },
  methods: {
    ...mapActions(["addReceptionByDocumentNumber", "fetchReceptions"]),
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    closeQr(){
      this.dialog2=false;
      this.close= true;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        //this.submitStatus = 'ERROR'
      } else {
        this.addReceptionByDocumentNumber({
          document_number: this.document_number,
        })
          .then((response) => {
            let params = {
              page: 0,
              size: 10,
            };
            // if (this.datei) {
            //   params["date"]= this.datei;
            // }
            this.mensaje(true, "success", response.data.message, 5000);
            this.fetchReceptions({ params: params });
            this.dialog2 = false;
          })
          .catch((err) => {
            this.mensaje(true, "error", err.errorMessage, 5000);
          });
      }
      // this.$refs.observer.validate();
    },
    closeDialog() {
      this.$v.$reset();
      this.document_number = null;
      this.dialog = false;
    },
  },
  created() {
    // if (this.date) {
    //   this.datei = this.date;
    // }
  },
};
</script>

<style>
</style>