<template>
  <div>
    <!-- <p class="decode-result">
      Last result: <b>{{ result }}</b>
    </p> -->
     <slot :dialog3="dialog3" :closeQr="closeQr" />
    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
      <div v-if="validationSuccess" class="validation-success">
        Tiene acceso
      </div>

      <div v-if="validationFailure" class="validation-failure">
        No tiene acceso
      </div>

      <div v-if="validationPending" class="validation-pending">
        Validando acceso
      </div>
    </qrcode-stream>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions } from "vuex";

export default {
  name: "QrScan",
  props : ["close"],
  watch : {
      close(value){
        if(value){
            this.turnCameraOff()
        }else{
            this.turnCameraOn()
        }
      }
  },
  components: {
    QrcodeStream,
  },
  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },

    validationSuccess() {
      return this.isValid === true;
    },

    validationFailure() {
      return this.isValid === false;
    },
    date() {
      return this.$route.query.date;
    },
  },
  data() {
    return {
      camera: "auto",
      result: null,
      isValid: undefined,
      snackbar: false,
      color: "",
      text: "",
      dialog3: false
    };
  },
  methods: {
    ...mapActions(["addReceptionByUserId", "fetchReceptions"]),
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    closeQr(){
        this.dialog3=false;
         this.turnCameraOff();
    },
    onInit(promise) {
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      this.addReceptionByUserId({ user_id: this.result })
        .then((response) => {
          let params = {
            page: 0,
            size: 10,
          };
          this.isValid = true;
          //   this.mensaje(true, "success", response.data.message, 5000);
          this.fetchReceptions({ params: params });
          this.turnCameraOn();
        })
        .catch((err) => {
          this.isValid = false;
          this.turnCameraOn();
          //   this.mensaje(true, "error", err.errorMessage, 5000);
        });
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
};
</script>
<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>