var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-10",attrs:{"height":"100%","tile":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Selecionar fecha","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.datei = null;
            _vm.$router.push("/recepcion");}},model:{value:(_vm.datei),callback:function ($$v) {_vm.datei=$$v},expression:"datei"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu = false;
          _vm.$router.push(("/recepcion?date=" + _vm.datei));}},model:{value:(_vm.datei),callback:function ($$v) {_vm.datei=$$v},expression:"datei"}})],1)],1),_c('v-card-text',{staticClass:"pa-1"},[_c('v-container',{staticClass:"pa-2",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('table-reception')],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-save",staticStyle:{"margin-right":"10px","color":"#479B25"},attrs:{"large":"","color":"#DAF4D0","dark":"","dense":""}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"mr-4",attrs:{"src":require("@/assets/images/DNI.svg")}}),_vm._v(" Ingresar DNI ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('form',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Ingresar DNI")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"DNI","error-messages":_vm.documentError,"counter":8,"required":""},model:{value:(_vm.document_number),callback:function ($$v) {_vm.document_number=$$v},expression:"document_number"}})],1)],1)],1),_c('small',[_vm._v("*indica campos requeridos")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submit}},[_vm._v(" Guardar ")])],1)],1)])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-save",attrs:{"large":"","color":"primary","dark":"","dense":""},on:{"click":function($event){_vm.close=false}}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"mr-6",attrs:{"src":require("@/assets/images/qr-code.svg")}}),_c('span',[_vm._v(" Escanear QR ")])],1)]}}]),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Escanear QR")])]),_c('v-card-text',[_c('v-container',[_c('QrScan',{attrs:{"close":_vm.close}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeQr()}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1),_c('v-snackbar',{staticClass:"mr-2",attrs:{"color":_vm.color,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"d-flex justify-center white--text"},[_vm._v(_vm._s(_vm.text))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }